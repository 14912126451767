<template>
  <div class="cnt-modal-delete">
    <div>
      <ion-label class="fw-500 fs-3">{{ $t('delete_items') }}</ion-label>
    </div>
    <div class="my-3">
      <ion-label>{{ $t('text_remove_item_in_cart') }}</ion-label>
    </div>
    <ion-button expand="block" class="w-100" size="small" @click="removeItem">{{ $t('yes') }}</ion-button>
    <ion-button expand="block" class="w-100" size="small" color="grey6" @click="closeModal">{{
      $t('no')
    }}</ion-button>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    removeItem: {
      type: Function,
      required: true
    },
    closeModal: {
      type: Function,
      required: true
    }
  }
});
</script>
<style lang="scss" scoped>
.cnt-modal-delete {
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
</style>
