<template>
  <ion-toolbar>
    <ion-grid>
      <ion-row class="align-center">
        <ion-col size="9" class="d-flex">
          <ion-label class="fs-4 fw-bold">{{ $t('cart') }}</ion-label>
        </ion-col>

        <ion-col size="3" class="header-right d-flex ion-justify-content-around align-center">
          <ion-icon
            id="open-branch-page-modal"
            class="icon-item pl-2"
            :icon="storefrontOutline"
            @click="setOpenModalBranchSelection(true)"
          />
          <ion-modal
            ref="modal"
            trigger="open-branch-page-modal"
            :initial-breakpoint="1"
            :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
            mode="ios"
            css-class="default-bottom-sheet-modal"
            :is-open="isOpenBranchSelection"
            @didDismiss="setOpenModalBranchSelection(false)"
          >
            <BranchSelection
              @refreshCart="$emit('refreshCart')"
              @closeModal="setOpenModalBranchSelection(false)"
            />
          </ion-modal>
          <notification-badge />
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</template>
<script>
import { storefrontOutline, notificationsOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import BranchSelection from '../../customer/BranchSelection';
import NotificationBadge from '../../shared/NotificationBadge';

export default defineComponent({
  components: {
    BranchSelection,
    NotificationBadge
  },
  setup() {
    const isOpenBranchSelection = ref(false);
    return {
      storefrontOutline,
      notificationsOutline,
      isOpenBranchSelection
    };
  },
  methods: {
    async setOpenModalBranchSelection(params) {
      this.isOpenBranchSelection = params;
    },
    goToNotificationPage() {
      this.ionRouter.navigate({
        routerLink: '/sale/notification'
      });
    }
  }
});
</script>
<style lang="scss" scoped>
ion-toolbar {
  --border-width: 0 !important;
}

.icon-item {
  font-size: 24px;
}

.header-right {
  text-align: end;
}

.header-right * {
  color: var(--ion-color-primary);
}
</style>
